<template>
  <v-app>
    <v-progress-linear
      :active="loading"
      :indeterminate="loading"
      absolute
      class="progress-linear"
      top
      color="primary"
      fixed
    />

    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DefaultLayout',
  components: {
  },
  props: {
  },
  data: () => {
    return {
    };
  },
  computed: {
    ...mapGetters({
      loading: 'app_layout/isLoading',
    }),
  },
};
</script>

<style scoped>
  .v-main {
    margin: 20px;
  }
</style>
