import axios from 'axios';
import configuration from '@/configurations';

class CompanyRepository {

  /**
   * @param {String} token
   * @param {Number} company_id
   */
  getCompany(token, company_id) {
    return axios
      .get(
        `${configuration.api_host}/v1/accounts/${company_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }

}

const company_repository = new CompanyRepository();

export {
    company_repository,
};
