import {
    company_repository,
    partner_repository,
  } from '@/repositories';

  const state = () => ({
    company_data: {},
    company_list_data: [],
  });

  const getters = {
    companyData: (state) => state.company_data,
    companyListData: (state) => state.company_list_data,
  };

  const actions = {
    getCompany({ commit, rootState}, company_id) {
      const token = rootState.user.token;

      return company_repository
        .getCompany(token, company_id)
        .then((response) => {
          const company = Object.assign(
            {},
            response
          );

          if (Array.isArray(company.office_list)) {
            company.main_office = company.office_list
              .find((office) => office.is_headquarters === true);
          } else {
            company.main_office = null;
          }

          commit('set_company_data', company);

          return company;
        });
    },
    async searchCompany({ commit, rootState}, params) {
      const token = rootState.user.token;
      const {
        domain,
        legal_name,
        siren,
      } = params;

      let response = null;

      if (siren !== undefined && siren.length === 9) {
        response = await partner_repository
          .searchCompanies(
              token,
              {
                corporation_siren_code_list: [
                  siren.replace(/\s/g, ''),
                ],
              }
          );

          if (response.account_list.length > 0) {
            commit('set_company_list_data', response.account_list);

            return response.account_list;
          }
      }

      if (domain !== undefined && domain.length > 2) {
        response = await partner_repository
          .searchCompanies(
              token,
              {
                domain_list: [
                  domain
                    .replace(/^(?:https?:\/\/)?(?:www\.)?/i, "")
                    .split('/')[0],
                ],
              }
          );

          if (response.account_list.length > 0) {
            commit('set_company_list_data', response.account_list);

            return response.account_list;
          }
      }

      if (legal_name !== undefined && legal_name.length > 0) {
        response = await partner_repository
          .searchCompanies(
              token,
              {
                quick_search: legal_name.trim(),
                sort: 'quick_search',
              }
          );

          if (response.account_list.length > 0) {
            commit('set_company_list_data', response.account_list);

            return response.account_list;
          }
      }

      commit('set_company_list_data', []);

      return [];
    },
  };

  const mutations = {
    set_company_data(state, data) {
      state.company_data = data;
    },
    set_company_list_data(state, data) {
      state.company_list_data = data;
    },
  };

  export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state,
  };
