'use strict';

const OrganizationChart = [
    {
        job_class_list: [
            {
                "id": 1,
                "name": "Gouvernance",
                "job_type_list": [
                    {
                        "id": 21,
                        "name": "Gouvernance",
                        "icon": "rudder",
                        "job_function_list": [
                            {
                                "id": 119,
                                "name": "Président du conseil administration",
                            },
                            {
                                "id": 120,
                                "name": "Membre du conseil administration",
                            },
                            {
                                "id": 121,
                                "name": "Président du conseil surveillance",
                            },
                            {
                                "id": 122,
                                "name": "Membre du conseil surveillance",
                            },
                            {
                                "id": 123,
                                "name": "Président du comité d'audit",
                            },
                            {
                                "id": 124,
                                "name": "Membre du comité d'audit",
                            },
                            {
                                "id": 125,
                                "name": "Président du comité de rémunérations, nominations",
                            },
                            {
                                "id": 126,
                                "name": "Membre du comité de rémunérations, nominations",
                            },
                            {
                                "id": 129,
                                "name": "Président du comité financier, stratégique",
                            },
                            {
                                "id": 130,
                                "name": "Membre du comité financier, stratégique",
                            },
                            {
                                "id": 131,
                                "name": "Président du comité d'éthique",
                            },
                            {
                                "id": 132,
                                "name": "Membre du comité d'éthique",
                            },
                            {
                                "id": 133,
                                "name": "Commissaire aux comptes",
                            },
                            {
                                "id": 134,
                                "name": "Censeur",
                            },
                            {
                                "id": 144,
                                "name": "Président / directeur d'association, fondation",
                            },
                        ],
                    },
                ],
            },
        ],
        level: 1,
        user_job_type_as_main: true,
    },
    {
        job_class_list: [
            {
                "id": 2,
                "name": "Direction générale",
                "job_type_list": [

                    {
                        "id": 15,
                        "name": "Direction stratégie",
                        "icon": "chess-knight",
                        "job_function_list": [
                            {
                                "id": 88,
                                "name": "Directeur / responsable stratégie, développement",
                            },
                            {
                                "id": 147,
                                "name": "Directeur / responsable de la transformation",
                            },
                            {
                                "id": 148,
                                "name": "Directeur / responsable organisation",
                            },
                            {
                                "id": 149,
                                "name": "Directeur / responsable fusions et acquisitions",
                            },
                            {
                                "id": 89,
                                "name": "Directeur / responsable études",
                            },
                            {
                                "id": 150,
                                "name": "Directeur de projets, responsable de mission",
                            },
                        ],
                    },
                    {
                        "id": 12,
                        "name": "Direction générale",
                        "icon": "temple",
                        "job_function_list": [
                            {
                                "id": 1,
                                "name": "Président",
                            },
                            {
                                "id": 2,
                                "name": "Directeur général, DGA, directeur exécutif",
                            },
                            {
                                "id": 3,
                                "name": "Directeur des opérations",
                            },
                            {
                                "id": 4,
                                "name": "Fondateur",
                            },
                            {
                                "id": 5,
                                "name": "Gérant",
                            },
                            {
                                "id": 145,
                                "name": "Délégué général",
                            },
                        ],
                    },
                    {
                        "id": 5,
                        "name": "Direction de business unit",
                        "icon": "chart-line",
                        "job_function_list": [
                            {
                                "id": 52,
                                "name": "Directeur / responsable business unit",
                            },
                            {
                                "id": 146,
                                "name": "Directeur / responsable d'établissement, de site",
                            },
                            {
                                "id": 54,
                                "name": "Directeur international",
                            },
                            {
                                "id": 53,
                                "name": "Directeur régional",
                            },
                        ],
                    },
                ],
            },
        ],
        level: 2,
        user_job_type_as_main: true,
    },
    {
        job_class_list: [
            {
                "id": 3,
                "name": "Fonctions supports",
                "icon": "calculator",
                "job_type_list": [
                    {
                        "id": 11,
                        "name": "Direction finance, administratif",
                        "job_function_list": [
                            {
                                "id": 6,
                                "name": "Secrétaire général",
                            },
                            {
                                "id": 97,
                                "name": "Directeur / responsable financier",
                            },
                            {
                                "id": 102,
                                "name": "Directeur / responsable comptable",
                            },
                            {
                                "id": 100,
                                "name": "Directeur / responsable contrôle de gestion",
                            },
                            {
                                "id": 98,
                                "name": "Directeur / responsable fiscal",
                            },
                            {
                                "id": 103,
                                "name": "Directeur / responsable trésorerie",
                            },
                            {
                                "id": 101,
                                "name": "Directeur / responsable audit",
                            },
                            {
                                "id": 104,
                                "name": "Directeur / responsable consolidation",
                            },
                            {
                                "id": 139,
                                "name": "Directeur / responsable crédit management",
                            },
                            {
                                "id": 151,
                                "name": "Directeur / responsable contentieux, recouvrement",
                            },
                            {
                                "id": 152,
                                "name": "Directeur / responsable administratif",
                            },
                        ],
                    },
                    {
                        "id": 7,
                        "name": "Direction des ressources humaines",
                        "job_function_list": [
                            {
                                "id": 59,
                                "name": "Directeur / responsable ressources humaines",
                            },
                            {
                                "id": 63,
                                "name": "Directeur / responsable formation",
                            },
                            {
                                "id": 62,
                                "name": "Directeur / responsable développement RH",
                            },
                            {
                                "id": 60,
                                "name": "Directeur / responsable rémunération",
                            },
                            {
                                "id": 64,
                                "name": "Directeur / responsable recrutement",
                            },
                            {
                                "id": 61,
                                "name": "Directeur / responsable relations sociales",
                            },
                        ],
                    },
                    {
                        "id": 9,
                        "name": "Direction développement durable, environnement, RSE",
                        "job_function_list": [
                            {
                                "id": 23,
                                "name": "Directeur / responsable développement durable, RSE",
                            },
                        ],
                    },
                    {
                        "id": 16,
                        "name": "Direction juridique, risk management",
                        "job_function_list": [
                            {
                                "id": 65,
                                "name": "Directeur / responsable juridique",
                            },
                            {
                                "id": 66,
                                "name": "Directeur / responsable risques, conformité, déontologie",
                            },
                            {
                                "id": 69,
                                "name": "Directeur / responsable affaires médicales",
                            },
                            {
                                "id": 68,
                                "name": "Directeur / responsable propriété intellectuelle, industrielle",
                            },
                            {
                                "id": 118,
                                "name": "Directeur / responsable assurances, actuariat",
                            },
                        ],
                    },
                    {
                        "id": 13,
                        "name": "Direction immobilière",
                        "job_function_list": [
                            {
                                "id": 92,
                                "name": "Directeur / responsable immobilier, urbanisme, habitat",
                            },
                        ],
                    },
                    {
                        "id": 6,
                        "name": "Direction achats, services généraux",
                        "job_function_list": [
                            {
                                "id": 70,
                                "name": "Directeur / responsable achats",
                            },
                            {
                                "id": 72,
                                "name": "Directeur / responsable achats matières premières, énergie",
                            },
                            {
                                "id": 75,
                                "name": "Directeur / responsable achats publics",
                            },
                            {
                                "id": 76,
                                "name": "Directeur / responsable achats hors production, prestations intellectuelles",
                            },
                            {
                                "id": 80,
                                "name": "Directeur / responsable travel management",
                            },
                            {
                                "id": 99,
                                "name": "Directeur / responsable services généraux",
                            },
                            {
                                "id": 82,
                                "name": "Directeur / responsable flotte de transport",
                            },
                        ],
                    },
                ],
            },
            {
                "id": 4,
                "name": "Marketing, commercial, communication",
                "icon": "bullhorn",
                "job_type_list": [
                    {
                        "id": 18,
                        "name": "Direction marketing",
                        "job_function_list": [
                            {
                                "id": 30,
                                "name": "Directeur / responsable marketing",
                            },
                            {
                                "id": 31,
                                "name": "Directeur / responsable produit, marque",
                            },
                            {
                                "id": 35,
                                "name": "Directeur / responsable partenariats, licensing",
                            },
                            {
                                "id": 138,
                                "name": "Directeur / responsable expérience client",
                            },
                            {
                                "id": 33,
                                "name": "Directeur / responsable marketing direct, relationnel",
                            },
                            {
                                "id": 36,
                                "name": "Directeur / responsable publicité",
                            },
                            {
                                "id": 32,
                                "name": "Directeur / responsable trade ou retail marketing, category management",
                            },
                        ],
                    },
                    {
                        "id": 19,
                        "name": "Direction digitale",
                        "job_function_list": [
                            {
                                "id": 37,
                                "name": "Directeur / responsable digital",
                            },
                            {
                                "id": 40,
                                "name": "Directeur / responsable e-commerce",
                            },
                            {
                                "id": 153,
                                "name": "Directeur / responsable data, CRM",
                            },
                            {
                                "id": 154,
                                "name": "Directeur / responsable protection des données personnelles",
                            },
                            {
                                "id": 38,
                                "name": "Directeur / responsable des contenus",
                            },
                        ],
                    },
                    {
                        "id": 4,
                        "name": "Direction communication, relations publiques",
                        "job_function_list": [
                            {
                                "id": 8,
                                "name": "Directeur / responsable communication",
                            },
                            {
                                "id": 9,
                                "name": "Directeur / responsable communication externe, relations presse",
                            },
                            {
                                "id": 10,
                                "name": "Directeur / responsable communication interne",
                            },
                            {
                                "id": 11,
                                "name": "Directeur / responsable relations publiques, institutionnelles",
                            },
                            {
                                "id": 140,
                                "name": "Directeur / responsable mécénat, sponsoring",
                            },
                            {
                                "id": 13,
                                "name": "Directeur / responsable communication événementielle",
                            },
                            {
                                "id": 12,
                                "name": "Directeur / responsable communication financière",
                            },
                        ],
                    },
                    {
                        "id": 3,
                        "name": "Direction commerciale",
                        "job_function_list": [
                            {
                                "id": 42,
                                "name": "Directeur / responsable commercial",
                            },
                            {
                                "id": 44,
                                "name": "Directeur / responsable ventes",
                            },
                            {
                                "id": 43,
                                "name": "Directeur / responsable grands comptes",
                            },
                            {
                                "id": 47,
                                "name": "Directeur / responsable business development",
                            },
                            {
                                "id": 46,
                                "name": "Directeur / responsable export",
                            },
                        ],
                    },
                    {
                        "id": 10,
                        "name": "Direction distribution et réseau",
                        "job_function_list": [
                            {
                                "id": 48,
                                "name": "Directeur / responsable distribution, réseau commercial",
                            },
                            {
                                "id": 49,
                                "name": "Directeur / responsable surfaces commerciales (magasin, hypermarché)",
                            },
                            {
                                "id": 143,
                                "name": "Directeur d'hôtel ou palace",
                            },
                        ],
                    },
                    {
                        "id": 2,
                        "name": "Direction artistique, création, design",
                        "job_function_list": [
                            {
                                "id": 51,
                                "name": "Directeur / responsable artistique, création",
                            },
                        ],
                    },
                ],
            },
            {
                "id": 5,
                "name": "Technique",
                "icon": "lightbulb-on",
                "job_type_list": [
                    {
                        "id": 8,
                        "name": "Direction des systèmes d'information",
                        "job_function_list": [
                            {
                                "id": 14,
                                "name": "Directeur / responsable systèmes d'information",
                            },
                            {
                                "id": 16,
                                "name": "Directeur / responsable informatique",
                            },
                            {
                                "id": 18,
                                "name": "Directeur / responsable de projets informatiques",
                            },
                            {
                                "id": 20,
                                "name": "Directeur / responsable systèmes, réseaux et télécoms",
                            },
                            {
                                "id": 22,
                                "name": "Directeur / responsable sécurité informatique",
                            },
                        ],
                    },
                    {
                        "id": 1,
                        "name": "Direction R&D, innovation",
                        "job_function_list": [
                            {
                                "id": 87,
                                "name": "Directeur / responsable R&D, innovation",
                            },
                        ],
                    },
                    {
                        "id": 28,
                        "name": "Direction technique, production, qualité",
                        "job_function_list": [
                            {
                                "id": 94,
                                "name": "Directeur / responsable usine",
                            },
                            {
                                "id": 96,
                                "name": "Directeur / responsable industriel",
                            },
                            {
                                "id": 95,
                                "name": "Directeur / responsable production",
                            },
                            {
                                "id": 83,
                                "name": "Directeur / responsable technique",
                            },
                            {
                                "id": 86,
                                "name": "Directeur / responsable bureau d'études, études techniques",
                            },
                            {
                                "id": 155,
                                "name": "Directeur / responsable maintenance",
                            },
                            {
                                "id": 84,
                                "name": "Directeur / responsable qualité",
                            },
                            {
                                "id": 85,
                                "name": "Directeur / responsable hygiène, sécurité, environnement",
                            },
                        ],
                    },
                    {
                        "id": 17,
                        "name": "Direction logistique, supply chain",
                        "job_function_list": [
                            {
                                "id": 81,
                                "name": "Directeur / responsable supply chain, logistique",
                            },
                        ],
                    },
                ],
            },
            {
                "id": 6,
                "name": "Métiers spécifiques",
                "icon": "bullseye-arrow",
                "job_type_list": [
                    {
                        "id": 26,
                        "name": "Métiers du conseil",
                        "job_function_list": [
                            {
                                "id": 7,
                                "name": "Associé",
                            },
                            {
                                "id": 55,
                                "name": "Directeur conseil, consultant senior",
                            },
                            {
                                "id": 56,
                                "name": "Consultant, chargé de mission",
                            },
                            {
                                "id": 57,
                                "name": "Avocat, collaborateur juridique",
                            },
                        ],
                    },
                    {
                        "id": 23,
                        "name": "Métiers de la banque et de la finance",
                        "job_function_list": [
                            {
                                "id": 110,
                                "name": "Directeur / responsable corporate finance et capital investissement",
                            },
                            {
                                "id": 111,
                                "name": "Directeur / responsable opérations sur les marchés",
                            },
                            {
                                "id": 116,
                                "name": "Directeur / responsable gestion de fortune, de patrimoine",
                            },
                        ],
                    },
                    {
                        "id": 25,
                        "name": "Métiers des médias et du journalisme",
                        "job_function_list": [
                            {
                                "id": 24,
                                "name": "Directeur  publication, éditeur",
                            },
                            {
                                "id": 27,
                                "name": "Directeur de la rédaction, rédacteur en chef",
                            },
                            {
                                "id": 25,
                                "name": "Directeur / responsable diffusion, abonnement",
                            },
                            {
                                "id": 26,
                                "name": "Directeur des programmes, production",
                            },
                        ],
                    },
                    {
                        "id": 29,
                        "name": "Métiers du comité d'entreprise",
                        "job_function_list": [
                            {
                                "id": 157,
                                "name": "Trésorier du comité d'entreprise",
                            },
                            {
                                "id": 156,
                                "name": "Secrétaire du comité d'entreprise",
                            },
                            {
                                "id": 158,
                                "name": "Membre du comité d'entreprise",
                            },
                        ],
                    },
                    {
                        "id": 24,
                        "name": "Métiers de l'administration publique",
                        "job_function_list": [
                            {
                                "id": 105,
                                "name": "Directeur général des services",
                            },
                            {
                                "id": 106,
                                "name": "Directeur de cabinet",
                            },
                            {
                                "id": 108,
                                "name": "Haut fonctionnaire",
                            },
                        ],
                    },
                ],
            },
            {
                "id": 0,
                "name": "Autres fonctions",
                "icon": "briefcase",
                "job_type_list": [
                    {
                        "id": 22,
                        "name": "Autres fonctions",
                        "job_function_list": [],
                    },
                ],
            },
        ],
        level: 3,
        user_job_type_as_main: false,
    },
];

const OrganizationChartConstants = {
    LAST_LEVEL: 3,
    OTHER_ID: 0,
};

export {
    OrganizationChart,
    OrganizationChartConstants,
};
