<template>
  <a
    :class="data.class"
    class="content-link pa-3"
    :href="`${configuration.desk_v1_host}/contact/${data.id}/profile?jwt=${token}&direction=${direction}`"
    target="_blank"
  >
    <div class="d-flex align-center">
      <div class="mr-2">
        <v-avatar size="40">
          <contact-photo
            :contact_id="data.id"
            :civility_id="data.civility_id"
          />
        </v-avatar>
      </div>

      <div>
        <div class="contact-full-name">
          {{ data.first_name }} {{ data.last_name }}
        </div>

        <div class="contact-job">
          {{ data.job_name }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
import configuration from '@/configurations';

import ContactPhoto from '@/components/elements/images/ContactPhoto';

export default {
  name: 'NewsCardContact',
  components: {
    ContactPhoto,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  computed: {
    ...mapGetters({
      direction: 'user/direction',
      token: 'user/token',
    }),
  },
};
</script>

<style scoped>
  .content-link {
    cursor: pointer;
    display: block;
    text-decoration: none;
  }

  .contact-full-name {
    color: #123354;
    font-size: 12px;
    font-weight: 500;
  }

  .contact-job {
    color: #123354;
    font-size: 11px;
  }
</style>
