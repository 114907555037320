<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        align="center"
        cols="12"
      >
        <organization-chart :company_id="company_id" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OrganizationChart from '@/components/elements/OrganizationChart';

export default {
  name: 'OrganizationChartPage',
  components: {
    OrganizationChart,
  },
  props: {
    company_id: Number,
  },
};
</script>
