<template>
  <div
    :class="data.class"
    class="d-flex align-center justify-space-between pa-3"
  >
    <div>
      <div
        v-for="type in data.types"
        :key="type.id"
        :style="'background-color:' + type.color"
        class="white--text d-inline-block rounded px-3 py-2 mr-2"
      >
        <div class="d-flex align-center">
          <div class="type-name d-flex align-center">
            {{ type.label }}
          </div>
        </div>
      </div>
    </div>

    <div class="date">
      Publié {{ data.publication_date | humanizeDate }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsCardType',
  props: {
    data: Object,
  },
};
</script>

<style scoped>
  .type-name {
    font-size: 12px;
    font-weight: 500;
  }

  .date {
    color: #9b9bbe;
    font-size: 12px;
    font-weight: 500;
  }
</style>
