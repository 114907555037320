const state = () => ({
  is_loading: false,
  is_opened_drawer: false,
  app_version: process.env.VUE_APP_VERSION | '1.0.0',
});

const getters = {
  isLoading: (state) => state.is_loading,
  isOpenedDrawer: (state) => state.is_opened_drawer,
  getAppVersion: (state) => state.app_version,
};

const actions = {
  setLoading({ commit }, is_loading) {
    return new Promise((resolve) => {
      commit('set_loading', is_loading);
      return resolve();
    });
  },
  setOpenedDrawer({ commit }, is_open) {
    return commit('is_opened_drawer', is_open);
  },
};

const mutations = {
  set_loading(state, is_loading) {
    state.is_loading = is_loading;
  },
  is_opened_drawer(state, is_open) {
    state.is_opened_drawer = is_open;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
