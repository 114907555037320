<script>
// Image wrapper created in order to
// prevent logging error whenever image is
// not found

import VImg from 'vuetify/lib/components/VImg';

// eslint-disable-next-line vue/require-direct-export
export default VImg.extend({
  name: 'ImgWrapper',
  methods: {
    onError() {
      this.$emit('error', this.src);
    },
  },
});
</script>
