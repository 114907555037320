import {
  GlobalConstants,
} from '@/constants';
import {
  authentication_repository,
  query_repository,
  user_repository,
} from '@/repositories';

const getDirectionFromLocalStorage = () => {
  return localStorage.direction
    ? localStorage.direction
    : null;
};

const state = () => ({
  authentication_status: '',
  back_url: null,
  direction: getDirectionFromLocalStorage(),
  is_connected: authentication_repository.isValidLocalStorageToken(),
  token: authentication_repository.getTokenFromLocalStorageIfIsValid(),
  token_data: authentication_repository.isValidLocalStorageToken()
    ? authentication_repository
      .decodeToken(authentication_repository
      .getTokenFromLocalStorageIfIsValid())
    : {},
  user: {},
  email: authentication_repository.getEmailLocalStorage(),
  password: authentication_repository.getPasswordLocalStorage(),
  user_data: user_repository.getUserDataLocalStorage(),
});

const getters = {
  isConnected: (state) => state.is_connected,
  authenticationStatus: (state) => state.authentication_status,
  backUrl: (state) => state.back_url,
  direction: (state) => state.direction,
  token: (state) => state.token,
  tokenData: (state) => state.token_data,
  email: (state) => state.email,
  password: (state) => state.password,
  userData: (state) => state.user_data,
};

const actions = {
  login({ commit }, { email, password }) {
    return new Promise((resolve, reject) => {
      commit('authentication_request');

      return authentication_repository.login(email, password)
        .then((response) => {
          authentication_repository.setTokenLocalStorage(response.jwt);

          commit(
            'authentication_success',
            {
              back_url: null,
              direction: null,
              token: response.jwt,
            }
          );

          return resolve(response);
        })
        .catch((error) => {
          commit('authentication_error');

          return reject(error);
        });
    });
  },
  logout({ commit }) {
    commit('logout');

    authentication_repository.removeTokenLocalStorage();
    authentication_repository.removeEmailLocalStorage();
    authentication_repository.removePasswordLocalStorage();
    user_repository.removeUserDataLocalStorage();
  },
  setConnection(
    { commit },
    {
      back_url,
      direction,
      token,
    }
  ) {
    authentication_repository.setTokenLocalStorage(token);
    localStorage.direction = direction;

    commit(
      'authentication_success',
      {
        back_url,
        direction,
        token,
      }
    );
  },
  getUserData({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.user_data !== null) {
        return resolve(state.user_data);
      }

      return user_repository.getUserById(state.token, state.token_data.user_id)
        .then((response) => {
          const user_data_stringified = JSON.stringify(response);

          user_repository.setUserDataLocalStorage(user_data_stringified);

          commit('setUserData', JSON.parse(user_data_stringified));

          return resolve(response);
        })
        .catch((error) => {
          return reject(error);
        });
      });
  },
  getNewsQueryList({ commit, state }) {
    return new Promise((resolve, reject) => {
      return query_repository
        .searchUserQueryList(
          state.token,
          state.token_data.user_id,
          {
            name: state.direction,
            query_type_id_list: [GlobalConstants.QUERY_TYPE_ID.ALERT],
          }
        )
        .then((response) => resolve(response.query_list))
        .catch(reject);
    });
  },
};

const mutations = {
  authentication_request(state) {
    state.authentication_status = 'loading';
  },
  authentication_success(
    state,
    {
      back_url,
      direction,
      token,
    }
  ) {
    state.authentication_status = 'success';
    state.is_connected = true;
    state.back_url = back_url;
    state.direction = direction;
    state.token = token;
    state.token_data = authentication_repository.decodeToken(token);
  },
  authentication_error(state) {
    state.authentication_status = 'error';
    state.is_connected = false;
  },
  set_back_url(state, back_url) {
    state.back_url = back_url;
  },
  logout(state) {
    state.status = '';
    state.token = null;
    state.is_connected = false;
    state.email = '';
    state.password = '';
    state.user_data = null;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setPassword(state, password) {
    state.password = password;
  },
  setUserData(state, user_data) {
    state.user_data = user_data;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
