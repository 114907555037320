import moment from 'moment';

import {
  news_repository,
  local_search_criteria_repository,
} from '@/repositories';


const state = () => ({
  news_list: [],
  loading: false,
  filters: {},
  limit: 10,
  offset: 0,
});

const getters = {
  newsList: (state) => state.news_list,
  loading: (state) => state.loading,
  limit: (state) => state.limit,
  offset: (state) => state.offset,
};

const actions = {
  searchNews({ commit, rootState, state }, filters) {
    return new Promise((resolve, reject) => {
      const token = rootState.user.token;
      const default_filters = {
        limit: state.limit,
        offset: 0,
        news_publication_date_max: moment().toISOString(),
        sort: [
          '-publication_date',
        ],
      };
      commit('set_filters', {
        ...default_filters,
        ...filters,
      });
      commit('search_news_request');
      return news_repository
        .getBusinessAlertNews(token, state.filters)
        .then((response) => {
          commit('search_news_success', response.news_list);
          return resolve(response);
        })
        .catch((error) => {
          commit('search_news_error');
          return reject(error);
        });
    });
  },
  searchNewsNextPage({ commit, rootState, state }) {
    return new Promise((resolve, reject) => {
      const token = rootState.user.token;
      commit('set_filters', {
        ...state.filters,
        offset: state.filters.offset + state.filters.limit,
      });
      commit('search_news_request');
      return news_repository
        .getBusinessAlertNews(token, state.filters)
        .then((response) => {
          commit('search_news_success_append', response.news_list);
          return resolve(response);
        })
        .catch((error) => {
          commit('search_news_error');
          return reject(error);
        });
    });
  },
  setOffset({ commit }, offset) {
    commit('set_offset', offset);
  },
  resetNewsList({ commit }) {
    commit('reset_news_list');
  },
  checkHaveSavedCriteria() {
    return local_search_criteria_repository.checkHaveSavedCriteriaByType('news');
  },
};

const mutations = {
  search_news_request(state) {
    state.loading = true;
  },
  search_news_success(state, data) {
    state.loading = false;
    state.news_list = data;
  },
  search_news_success_append(state, data) {
    state.loading = false;
    state.news_list = state.news_list.concat(data);
  },
  search_news_error(state) {
    state.loading = false;
  },
  set_offset(state, offset) {
    state.offset = offset;
  },
  reset_news_list(state) {
    state.news_list = [];
  },
  set_filters(state, filters) {
    state.filters = filters;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
