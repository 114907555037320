<template>
  <v-container
    v-if="loaded_company_data"
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        align="center"
        cols="12"
      >
        <companies-list :company_list="company_list_data" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import CompaniesList from '@/components/elements/CompaniesList';

export default {
  name: 'CompaniesSearchPage',
  components: {
    CompaniesList,
  },
  data() {
    return {
      loaded_company_data: false,
    };
  },
  computed: {
    ...mapGetters({
      company_list_data: 'company/companyListData',
    }),
  },
  mounted() {
    this.isLoading(true);

    this.searchCompany(this.$route.query)
      .then((company_list) => {
        if (company_list.length === 1) {
          this.$router.push({
            name: 'OrganizationChartPage',
            params: {
              company_id: company_list[0].id,
            },
          });
        }
      })
      .finally(() => {
        this.isLoading(false);
        this.loaded_company_data = true;
      });
  },
  methods: {
    ...mapActions({
      isLoading: 'app_layout/setLoading',
      searchCompany: 'company/searchCompany',
    }),
  },
};
</script>
