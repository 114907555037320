<template>
  <fragment>
    <speaker-news-card
      v-if="news_type_id == news_types.SPEAKER.ID"
      :data="data"
    />
    <press-news-card
      v-if="news_type_id == news_types.PRESS.ID"
      :data="data"
    />
    <nomination-news-card
      v-if="news_type_id == news_types.NOMINATION.ID && data.person"
      :data="data"
    />
    <departure-news-card
      v-if="news_type_id == news_types.DEPARTURE.ID"
      :data="data"
    />
    <business-opportunity-news-card
      v-if="news_type_id == news_types.BUSINESS_OPPORTUNITY.ID"
      :data="data"
    />
  </fragment>
</template>

<script>
import { NewsConstants } from '@/constants';

import SpeakerNewsCard from '@/components/elements/news/news_cards/SpeakerNewsCard';
import PressNewsCard from '@/components/elements/news/news_cards/PressNewsCard';
import NominationNewsCard from '@/components/elements/news/news_cards/NominationNewsCard';
import DepartureNewsCard from '@/components/elements/news/news_cards/DepartureNewsCard';
import BusinessOpportunityNewsCard from '@/components/elements/news/news_cards/BusinessOpportunityNewsCard';

export default {
  name: 'NewsCard',
  components: {
    SpeakerNewsCard,
    PressNewsCard,
    NominationNewsCard,
    DepartureNewsCard,
    BusinessOpportunityNewsCard,
  },
  props: {
    'data': Object,
  },
  data() {
    return {
      news_types: NewsConstants.TYPE,
    };
  },
  computed: {
    news_type_id: function() {
      return this.data.news_type.id;
    },
  },
};
</script>
