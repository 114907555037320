<template>
  <v-card class="mx-auto">
    <news-card-company
      :data="company_data"
      :queries="matched_queries"
    />

    <news-card-type :data="type_data" />

    <news-card-content :data="content_data">
      <div class="source-name">
        {{ source_name }}
      </div>

      <div class="description mt-2">
        {{ description }}
      </div>

      <div class="sub-description mt-2">
        {{ sub_description }}... <strong>Lire la suite</strong>
      </div>
    </news-card-content>
  </v-card>
</template>

<script>
import _ from 'lodash';
import configuration from '@/configurations';
import {NewsConstants} from '@/constants';

import NewsCardCompany from '@/components/elements/news/news_cards/elements/NewsCardCompany';
import NewsCardContent from '@/components/elements/news/news_cards/elements/NewsCardContent';
import NewsCardType from '@/components/elements/news/news_cards/elements/NewsCardType';

export default {
  name: 'PressNewsCard',
  components: {
    NewsCardCompany,
    NewsCardContent,
    NewsCardType,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      company_data: {
        class: 'mt-3',
        company_id: this.data.company.id,
        company_name: this.data.company.name,
      },
      content_data: {
        class: '',
        link: `${configuration.desk_v1_host}/account/${this.data.company.id}/news/press/${this.data.press.id}`,
      },
      type_data: {
        class: '',
        publication_date: this.data.publication_date,
        types: [
          {
            icon: NewsConstants.TYPE.PRESS.ICON,
            id: NewsConstants.TYPE.PRESS.ID,
            color: NewsConstants.TYPE.PRESS.COLOR,
            label: NewsConstants.TYPE.PRESS.LABEL,
          },
        ],
      },
      id: this.data.id,
      description: this.data.source.headline,
      sub_description: `${this.data.source.content.substring(0, 220)}`,
      publication_date: this.data.publication_date,
      company_id: this.data.company.id,
      matched_queries: this.data.matched_queries,
    };
  },
  computed: {
    contact_id: function() {
      return _.get(this, 'data.person.id', null);
    },
    source_name: function() {
      return _.get(this, 'data.company.name', this.data.source.name);
    },
  },
};
</script>

<style scoped>
  .source-name {
    color: #131336;
    font-size: 16px;
    font-weight: 500;
  }

  .description {
    color: #123354;
    font-size: 12px;
    font-weight: 500;
  }

  .sub-description {
    color: #123354;
    font-size: 12px;
  }

  .published-date {
    color: #9b9bbe;
    font-size: 12px;
  }
</style>
