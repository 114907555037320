import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Fragment from 'vue-fragment';
import VueMoment from 'vue-moment';
import './filters/GlobalFilters';

Vue.use(Fragment.Plugin);
Vue.use(VueMoment);

import DefaultLayout from './components/layouts/DefaultLayout.vue';

Vue.component('DefaultLayout', DefaultLayout);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
