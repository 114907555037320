'use strict';

const TYPE = {
    NOMINATION: {
        ICON: 'mdi-trophy-variant',
        ID: 2,
        COLOR: "rgb(252, 75, 114)",
        LABEL: "Nomination",
    },
    DEPARTURE: {
        ICON: 'mdi-logout',
        ID: 3,
        COLOR: "rgb(164, 220, 110)",
        LABEL: "Départ",
    },
    BIRTHDAY: {
        ICON: 'mdi-cake-variant',
        ID: 4,
        COLOR: "rgb(117, 147, 211)", // default
        LABEL: "Anniversaire",
    },
    FUNDRAISING: {
        ICON: 'mdi-briefcase-variant',
        ID: 5,
        COLOR: "rgb(117, 147, 211)", // default
        LABEL: "Levée de fonds",
    },
    CONFERENCE: {
        ICON: 'mdi-microphone',
        ID: 6,
        COLOR: "rgb(117, 147, 211)", // default
        LABEL: "Conférence",
    },
    SPEAKER: {
        ICON: 'mdi-microphone',
        ID: 7,
        COLOR: "rgb(0, 182, 159)",
        LABEL: "Speaker",
    },
    BUSINESS_OPPORTUNITY: {
        ICON: 'mdi-briefcase-variant',
        ID: 11,
        COLOR: "rgb(117, 147, 211)",
        LABEL: "Opportunité business",
    },
    PRESS: {
        ICON: 'mdi-pencil',
        ID: 12,
        COLOR: "rgb(255, 173, 54)",
        LABEL: "Presse",
    },
    NEW_ENTRY: {
        ICON: 'mdi-account-plus',
        ID: 13,
        COLOR: "rgb(117, 147, 211)", // default
        LABEL: "Nouveau contact",
    },
};
const BUSINESS_OPPORTUNITY_TYPE = {
    EXTERNAL_GROWTH_ID: 1,
    BUSINESS_DEVELOPMENT_ID: 2,
    NEW_SITE_ID: 3,
    DIVERSIFICATION_ID: 4,
    MARKETING_PROJECT_ID: 5,
    INTERNATIONAL_DEVELOPMENT_ID: 6,
    DIGITAL_PROJECT_ID: 7,
    IT_PROJECT_ID: 8,
    RD_INVESTMENT_ID: 9,
    COMPANIES_IN_TROUBLE_ID: 10,
    RECRUITMENT_ID: 11,
    PURCHASE_PROJECT_ID: 12,
    FINANCE_PROJECT_ID: 13,
    CAR_FLEET_ID: 14,
    SERVICE_CAR_ID: 15,
    FUNDRAISING_ID: 16,
};

// Types ID + BUSINESS_O_ID + 13
const TAG = {
    NOMINATION: TYPE.NOMINATION,
    DEPARTURE: TYPE.DEPARTURE,
    BIRTHDAY: TYPE.BIRTHDAY,
    CONFERENCE: TYPE.CONFERENCE,
    SPEAKER: TYPE.SPEAKER,
    PRESS: TYPE.PRESS,
    NEW_ENTRY: TYPE.NEW_ENTRY,

    EXTERNAL_GROWTH: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.EXTERNAL_GROWTH_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Croissance externe",
    },
    BUSINESS_DEVELOPMENT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.BUSINESS_DEVELOPMENT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Développement de l'activité",
    },
    NEW_SITE: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.NEW_SITE_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Nouveau site",
    },
    DIVERSIFICATION: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.DIVERSIFICATION_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Diversification",
    },
    MARKETING_PROJECT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.MARKETING_PROJECT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Projet marketing",
    },
    INTERNATIONAL_DEVELOPMENT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.INTERNATIONAL_DEVELOPMENT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Développement international",
    },
    DIGITAL_PROJECT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.DIGITAL_PROJECT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Projet digital",
    },
    IT_PROJECT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.IT_PROJECT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Projet IT",
    },
    RD_INVESTMENT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.RD_INVESTMENT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Investissement R&D",
    },
    COMPANIES_IN_TROUBLE: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.COMPANIES_IN_TROUBLE_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Entreprises en difficulté",
    },
    RECRUITMENT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.RECRUITMENT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Recrutement et projet RH",
    },
    PURCHASE_PROJECT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.PURCHASE_PROJECT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "PURCHASE_PROJECT",
    },
    FINANCE_PROJECT: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.FINANCE_PROJECT_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "FINANCE_PROJECT",
    },
    CAR_FLEET: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.CAR_FLEET_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Flotte auto",
    },
    SERVICE_CAR: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.SERVICE_CAR_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "SERVICE_CAR",
    },
    FUNDRAISING: {
        ICON: 'mdi-briefcase-variant',
        ID: BUSINESS_OPPORTUNITY_TYPE.FUNDRAISING_ID + 13,
        COLOR: TYPE.BUSINESS_OPPORTUNITY.COLOR,
        LABEL: "Levée de fonds",
    },
};

const DEFAULT_MANAGED_NEWS_TYPE_LIST = [
    TYPE.NOMINATION.ID,
    TYPE.SPEAKER.ID,
    TYPE.DEPARTURE.ID,
    TYPE.PRESS.ID,
    TYPE.BUSINESS_OPPORTUNITY.ID,
];

const DEFAULT_MANAGED_NEWS_TAG_LIST = [
    TAG.NOMINATION.ID,
    TAG.DEPARTURE.ID,
    TAG.SPEAKER.ID,
    TAG.PRESS.ID,

    TAG.EXTERNAL_GROWTH.ID,
    TAG.BUSINESS_DEVELOPMENT.ID,
    TAG.NEW_SITE.ID,
    TAG.DIVERSIFICATION.ID,
    TAG.MARKETING_PROJECT.ID,
    TAG.INTERNATIONAL_DEVELOPMENT.ID,
    TAG.DIGITAL_PROJECT.ID,
    TAG.IT_PROJECT.ID,
    TAG.RD_INVESTMENT.ID,
    TAG.COMPANIES_IN_TROUBLE.ID,
    TAG.RECRUITMENT.ID,
    TAG.PURCHASE_PROJECT.ID,
    TAG.FINANCE_PROJECT.ID,
    TAG.FUNDRAISING.ID,
];

const SOURCE = {
    MELTWATER: 'Meltwater',
};

const NewsConstants = {
    TAG,
    TYPE,
    BUSINESS_OPPORTUNITY_TYPE,
    DEFAULT_MANAGED_NEWS_TYPE_LIST,
    DEFAULT_MANAGED_NEWS_TAG_LIST,
    SOURCE,
};

export {
    NewsConstants,
};
