import axios from 'axios';
import configuration from '@/configurations';

class ContactRepository {

  /**
   * @param {String} token
   * @param {Number} contact_id
   */
  getContact(token, contact_id) {
    return axios
      .get(
        `${configuration.api_host}/v1/contacts/${contact_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }

  /**
   * @param {String} token
   * @param {Object} criteria
   */
  getOrganizationChart(token, criteria) {
    return axios
      .post(
        `${configuration.api_host}/v1/contacts/organigramme`,
        criteria,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }

  /**
   * @param {String} token
   * @param {Object} criteria
   * @param {Array<string>} criteria.sort exemple: ["-job_start_date"]
   * @param {Number} criteria.limit
   * @param {Number} criteria.offset
   * @param {Boolean} criteria.job_active
   * @param {Array<Number>} criteria.job_function_id_list
   */
  searchContact(token, criteria) {
    return axios
      .post(
        `${configuration.api_host}/v1/advanced-search/contacts/active`,
        criteria,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }

}

const contact_repository = new ContactRepository();

export {
  contact_repository,
};
