<template>
  <div
    :class="[data.class, data.link !== undefined ? 'cursor-pointer' : '']"
    class="pa-3"
    @click="goTo"
  >
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NewsCardContent',
  props: {
    data: Object,
  },
  methods: {
    goTo() {
      if (this.data.link !== undefined) {
        window.open(`${this.data.link}?jwt=${this.token}&direction=${this.direction}`);
      }
    },
  },
  computed: {
    ...mapGetters({
      direction: 'user/direction',
      token: 'user/token',
    }),
  },
};
</script>

<style scoped>
  .cursor-pointer {
    cursor: pointer;
  }
</style>
