<template>
  <div class="modal">
    <div class="title">
      {{ data.name }}

      <div class="close">
        <v-icon @click="$emit('update:show_dialog', false)">
          mdi-close
        </v-icon>
      </div>
    </div>

    <div class="content">
      <div class="contact-list">
        <contact-card
          v-for="contact in data.contact_list"
          :key="contact.id"
          :contact="contact"
          class="contact-card"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactCard from '@/components/elements/ContactCard';

export default {
  name: 'ContactList',
  components: {
    ContactCard,
  },
  props: {
    data: Object,
  },
};
</script>

<style scoped>
  .modal {
    background-color: #ffffff;
  }

  .title {
    background-color: #f5f5fb;
    color: #131336;
    font-size: 20px;
    font-weight: 500;
    padding: 20px;
    position: relative;
  }

  .close {
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .close i {
    color: #9b9bbe;
    cursor: pointer;
    font-size: 26px;
  }

  .content {
    padding: 20px;
  }

  .contact-list {
    display: flex;
    flex-wrap: wrap;
  }

  .contact-card {
    margin-left: 12px;
    margin-top: 12px;
    min-height: 100px;
    width: 280px;
  }
</style>
