<template>
  <v-card class="mx-auto">
    <news-card-company
      :data="company_data"
      :queries="matched_queries"
    />

    <news-card-type :data="type_data" />

    <news-card-contact :data="contact_data" />

    <news-card-content :data="content_data">
      <div class="description">
        {{ description }}
      </div>

      <div class="sub-description mt-2">
        {{ sub_description }}... <strong>Lire la suite</strong>
      </div>
    </news-card-content>
  </v-card>
</template>

<script>
import _ from 'lodash';
import configuration from '@/configurations';
import {NewsConstants} from '@/constants';

import NewsCardCompany from '@/components/elements/news/news_cards/elements/NewsCardCompany';
import NewsCardContact from '@/components/elements/news/news_cards/elements/NewsCardContact';
import NewsCardContent from '@/components/elements/news/news_cards/elements/NewsCardContent';
import NewsCardType from '@/components/elements/news/news_cards/elements/NewsCardType';

export default {
  name: 'BusinessOpportunityNewsCard',
  components: {
    NewsCardCompany,
    NewsCardContact,
    NewsCardContent,
    NewsCardType,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      contact_data: {
        civility_id: this.data.person.civility.id,
        class: '',
        id: this.data.person.id,
        first_name: this.data.person.first_name,
        job_name: this.data.person.main_job.name,
        last_name: this.data.person.last_name,
      },
      company_data: {
        class: 'mt-3',
        company_id: this.data.company.id,
        company_name: this.data.company.name,
      },
      content_data: {
        class: '',
        link: `${configuration.desk_v1_host}/account/${this.data.company.id}/news/business-opportunity/${this.data.business_opportunity.id}`,
      },
      type_data: {
        class: '',
        publication_date: this.data.publication_date,
        types: [
          {
            icon: NewsConstants.TYPE.BUSINESS_OPPORTUNITY.ICON,
            id: this.data.business_opportunity_type.id,
            color: NewsConstants.TYPE.BUSINESS_OPPORTUNITY.COLOR,
            label: this.data.business_opportunity_type.name,
          },
        ],
      },
      company_id: this.data.company.id,
      description: this.data.sample.title,
      sub_description: `${this.data.sample.content.substring(0, 100)}`,
      id: this.data.id,
      publication_date: this.data.publication_date,
      title: this.data.company.name,
      matched_queries: this.data.matched_queries,
    };
  },
  computed: {
    contact_id: function() {
      return _.get(this, 'data.person.id', null);
    },
  },
};
</script>

<style scoped>
  .description {
    color: #123354;
    font-size: 12px;
    font-weight: 500;
  }

  .sub-description {
    color: #123354;
    font-size: 12px;
  }

  .published-date {
    color: #9b9bbe;
    font-size: 12px;
  }
</style>
