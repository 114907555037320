import Vue from 'vue';
import VueRouter from 'vue-router';

import {
  CompaniesSearchPage,
  NewsPage,
  OrganizationChartPage,
} from '@/components/pages';

import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/companies/search',
  },
  {
    path: '/companies/search',
    name: 'CompaniesSearchPage',
    component: CompaniesSearchPage,
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/organization-chart/:company_id',
    name: 'OrganizationChartPage',
    component: OrganizationChartPage,
    props(route) {
      const props = { ...route.params };

      props.company_id = Number(props.company_id);

      return props;
    },
    meta: {
      layout: 'DefaultLayout',
    },
  },
  {
    path: '/news',
    name: 'NewsPage',
    component: NewsPage,
    meta: {
      layout: 'DefaultLayout',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  const query = Object.assign({}, to.query);

  if (query.token !== undefined) {
    store.dispatch(
      'user/setConnection',
      {
        back_url: query.back_url,
        direction: query.direction,
        token: query.token,
      }
    );

    delete query.back_url;
    delete query.direction;
    delete query.token;

    return next({
      path: to.path,
      query,
    });
  }

  next();
});

export default router;
