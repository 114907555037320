import axios from 'axios';
import configuration from '@/configurations';

class PartnerRepository {

  /**
   * @param {String} token
   * @param {Object} criteria
   */
  searchCompanies(token, criteria) {
    return axios
      .post(
        `${configuration.api_host}/v1/getquanty-partner/companies/blind`,
        criteria,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }
}

const partner_repository = new PartnerRepository();

export {
  partner_repository,
};
