<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        align="center"
        cols="12"
      >
        <div class="companies-list">
          <div
            v-if="company_list.length !== 0"
            class="companies-list-title"
          >
            Entreprises ({{ company_list.length }})
          </div>

          <router-link
            v-for="company in company_list"
            :key="company.id"
            :to="{ name: 'OrganizationChartPage', params: { company_id: company.id } }"
            class="companies-list-item"
          >
            <div class="companies-list-logo">
              <company-logo
                v-if="true"
                :company_id="company.id"
              />
            </div>

            <div class="companies-list-informations">
              <div class="companies-list-name">
                {{ company.name }}
                <template v-if="company.group_company.id !== 0">
                  ({{ company.group_company.name }})
                </template>
              </div>

              <div class="companies-list-counter">
                {{ company.nb_contacts }} contact{{ company.nb_contacts > 1 ? 's' : '' }}
              </div>

              <div
                v-if="getMainOffice(company) !== null"
                class="companies-list-address"
              >
                {{ getMainOffice(company).address.city.name }} ({{ getMainOffice(company).address.department.id }})
              </div>
            </div>
          </router-link>

          <div
            v-if="company_list.length === 0"
            class="companies-list-empty"
          >
            Oups... L'organigramme auquel vous souhaitez accéder n'est pas disponible dans Nomination. Vous aurez plus de chance la prochaine fois...
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyLogo from '@/components/elements/images/CompanyLogo';

export default {
  name: 'OrganizationChart',
  components: {
    CompanyLogo,
  },
  props: {
    company_list: Array,
  },
  data() {
    return {
      loaded_company_data: false,
    };
  },
  methods: {
    getMainOffice(company) {
      if (
        Array.isArray(company.office_list)
        && company.office_list.length > 0
      ) {
        return company.office_list[0];
      }

      return null;
    },
  },
};
</script>

<style scoped>
  .companies-list {
    max-width: 500px;
  }

  .companies-list-empty {
    color: #a3a495;
    line-height: 26px;
    text-align: left;
  }

  .companies-list-title {
    color: #5c839a;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 15px;
    text-align: left;
    text-transform: uppercase;
  }

  .companies-list-item {
    border: 1px solid #deded5;
    border-radius: 4px;
    display: flex;
    margin-bottom: 10px;
    padding: 15px;
    text-decoration: none;
  }

  .companies-list-logo {
    border: 1px solid #ecece1;
    border-radius: 4px;
    display: flex;
    flex: 0 0 50px;
    height: 50px;
    margin-right: 10px;
    min-width: 0;
  }

  .companies-list-informations {
    flex: 1;
    text-align: left;
  }

  .companies-list-name {
    color: #489aff;
    font-size: 16px;
    font-weight: 500;
  }

  .companies-list-counter {
    color: #16243f;
    font-size: 14px;
    font-weight: 400;
  }

  .companies-list-address {
    color: #98b4c2;
    font-size: 12px;
    font-style: italic;
  }
</style>
