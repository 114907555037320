import Vue from 'vue';
import Vuex from 'vuex';

import appLayoutStore from './modules/appLayoutStore';
import companyStore from './modules/companyStore';
import contactStore from './modules/contactStore';
import newsPageStore from './modules/newsPageStore';
import userStore from './modules/userStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app_layout: appLayoutStore,
    company: companyStore,
    contact: contactStore,
    news_page: newsPageStore,
    user: userStore,
  },
});
