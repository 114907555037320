import axios from 'axios';
import configuration from '@/configurations';
import jwt_decode from 'jwt-decode';

class AuthenticationRepository {
  login(login, password) {
    return axios
      .post(`${configuration.api_host}/v1/authentication/auth/local`,
        {
          login,
          password,
        }
      )
      .then((result) => result.data);
  }

  getTokenLocalStorage() {
    return localStorage.token ? localStorage.token : null;
  }

  setTokenLocalStorage(token) {
    localStorage.token = token;
  }

  removeTokenLocalStorage() {
    localStorage.removeItem('token');
  }

  getEmailLocalStorage() {
    return localStorage.user_email
      ? localStorage.user_email
      : '';
  }

  setEmailLocalStorage(user_email) {
    localStorage.user_email = user_email;
  }

  removeEmailLocalStorage() {
    localStorage.removeItem('user_email');
  }

  getPasswordLocalStorage() {
    return localStorage.user_password
      ? localStorage.user_password
      : '';
  }

  setPasswordLocalStorage(user_password) {
    localStorage.user_password = user_password;
  }

  removePasswordLocalStorage() {
    localStorage.removeItem('user_password');
  }

  isValidLocalStorageToken() {
    const local_token = this.getTokenLocalStorage();
    return this.isValidToken(local_token);
  }

  getTokenFromLocalStorageIfIsValid() {
    const local_token = this.getTokenLocalStorage();
    if (this.isValidToken(local_token)) {
      return local_token;
    }
    return null;
  }

  isValidToken(token) {
    if (token == null || token == undefined) {
      return false;
    }

    try {
      const now_timestamp = Math.floor(Date.now() / 1000);
      const decoded_token = this.decodeToken(token);

      return (decoded_token.exp > now_timestamp);
    } catch {
      return false;
    }
  }

  decodeToken(token) {
    return jwt_decode(token);
  }
}

const authentication_repository = new AuthenticationRepository();

export {
  authentication_repository,
};
