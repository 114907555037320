<template>
  <v-container
    v-if="loaded_company_data"
    class="pa-0"
    fluid
  >
    <v-row>
      <v-col
        align="center"
        cols="12"
      >
        <div class="organization-chart-container">
          <div
            v-for="(organization_chart_row, row_index) in filterRowList(organization_chart_data)"
            :key="organization_chart_row.level"
            class="organization-chart-row"
            :class="{'organization-chart-line-center-horizontal': row_index === 0, 'organization-chart-line-top-horizontal': row_index !== 0, 'organization-chart-line-bottom-vertical': row_index !== filterRowList(organization_chart_data).length -1}"
          >
            <template
              v-if="organization_chart_row.user_job_type_as_main"
            >
              <template
                v-for="organization_chart_job_class
                  in filterJobClassList(organization_chart_row.job_class_list)"
              >
                <v-dialog
                  :key="organization_chart_job_class.id"
                  v-model="show_dialog"
                  content-class="dialog-main"
                  :retain-focus="false"
                  width="100%"
                >
                  <template #activator="{ on, attrs }">
                    <div
                      v-for="organization_chart_job_type
                        in filterJobTypeList(organization_chart_job_class.job_type_list)"
                      :key="organization_chart_job_type.id"
                      class="organization-chart-bloc"
                      :class="{'organization-chart-line-top-vertical': row_index !== 0}"
                      v-bind="attrs"
                      v-on="on"
                      @click="showContacts(organization_chart_job_type)"
                    >
                      <div class="organization-chart-main">
                        <div class="organization-chart-icon">
                          <svg viewBox="0 0 32 32">
                            <use :xlink:href="getSvgLink(organization_chart_job_type.icon)" />
                          </svg>
                        </div>

                        <div class="organization-chart-category">
                          {{ organization_chart_job_type.name }}
                        </div>

                        <div class="organization-chart-counter">
                          {{ organization_chart_job_type.counter }}
                        </div>
                      </div>
                    </div>
                  </template>

                  <contact-list
                    :data="modal_data"
                    :show_dialog.sync="show_dialog"
                  />
                </v-dialog>
              </template>
            </template>

            <template v-else>
              <div
                v-for="organization_chart_job_class
                  in filterJobClassList(organization_chart_row.job_class_list)"
                :key="organization_chart_job_class.id"
                class="organization-chart-bloc"
                :class="{'organization-chart-line-top-vertical': row_index !== 0}"
                @click="toggleJobTypeList(organization_chart_job_class)"
              >
                <div class="organization-chart-main">
                  <div class="organization-chart-icon">
                    <svg viewBox="0 0 32 32">
                      <use :xlink:href="getSvgLink(organization_chart_job_class.icon)" />
                    </svg>
                  </div>

                  <div class="organization-chart-category">
                    {{ organization_chart_job_class.name }}
                  </div>

                  <div class="organization-chart-counter">
                    {{ organization_chart_job_class.counter }}
                  </div>
                </div>

                <transition name="slide">
                  <div
                    v-if="organization_chart_job_class.open"
                    class="organization-chart-content"
                    @click.stop
                  >
                    <v-dialog
                      :key="organization_chart_job_class.id"
                      v-model="show_dialog"
                      content-class="dialog-main"
                      :retain-focus="false"
                      width="100%"
                    >
                      <template #activator="{ on, attrs }">
                        <div
                          v-for="organization_chart_job_type
                            in filterJobTypeList(organization_chart_job_class.job_type_list)"
                          :key="organization_chart_job_type.id"
                          class="organization-chart-sub-category"
                          v-bind="attrs"
                          v-on="on"
                          @click="showContacts(organization_chart_job_type)"
                        >
                          <div class="organization-chart-sub-category-name">
                            {{ organization_chart_job_type.name }}
                          </div>

                          <div class="organization-chart-sub-category-counter">
                            {{ organization_chart_job_type.counter }}
                          </div>
                        </div>
                      </template>

                      <contact-list
                        :data="modal_data"
                        :show_dialog.sync="show_dialog"
                      />
                    </v-dialog>
                  </div>
                </transition>
              </div>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-btn 
      variant="elevated" 
      color="blue" 
      class="mt-5 white--text"
      @click="$router.go(-1)"
    >
      Retour
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ContactList from '@/components/elements/ContactList';

export default {
  name: 'OrganizationChart',
  components: {
    ContactList,
  },
  props: {
    company_id: Number,
  },
  data() {
    return {
      loaded_company_data: false,
      modal_data: {},
      show_dialog: false,
    };
  },
  computed: {
    ...mapGetters({
      company: 'company/companyData',
      organization_chart_data: 'contact/organizationChartData',
    }),
  },
  mounted() {
    this.isLoading(true);

    this.getCompany(this.company_id)
      .then(() => {
        return this.getOrganizationChart({
          company_id_list: [
            this.company_id,
          ],
          limit: this.company.nb_contacts,
        });
      })
      .then(() => {
        this.isLoading(false);
        this.loaded_company_data = true;
      });
  },
  methods: {
    ...mapActions({
      getCompany: 'company/getCompany',
      getOrganizationChart: 'contact/getOrganizationChart',
      isLoading: 'app_layout/setLoading',
    }),
    filterRowList(row_list) {
      return row_list.filter((row) => row.show);
    },
    filterJobClassList(job_class_list) {
      return job_class_list.filter((job_class) => job_class.show);
    },
    filterJobTypeList(job_type_list) {
      return job_type_list.filter((job_type) => job_type.show);
    },
    getSvgLink(svg_name) {
      const svg_link = require(`@/assets/icons/${svg_name}.svg`);

      return `${svg_link}#Desk`;
    },
    showContacts(job_type) {
      this.modal_data = job_type;
    },
    toggleJobTypeList(organization_chart_job_class) {
      const is_open = !organization_chart_job_class.open;

      this.organization_chart_data.forEach((row) => {
        row.job_class_list.forEach((job_class) => {
          job_class.open = false;
        });
      });

      organization_chart_job_class.open = is_open;
    },
  },
};
</script>

<style scoped>
  svg {
    height: 32px;
    width: 32px;
  }

  .organization-chart-container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .organization-chart-row {
    align-items: flex-start;
    display: flex;
    margin-top: 32px;
    position: relative;
  }

  .organization-chart-row:first-child {
    margin-top: 0;
  }

  .organization-chart-bloc {
    background-color: #f5f5fb;
    border: 1px solid #bbc0d7;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 16px;
    padding: 16px 12px 12px;
    position: relative;
    width: 144px;
    z-index: 10;
  }

  .organization-chart-bloc:last-child {
    margin-right: 0;
  }

  .organization-chart-main {
    height: 100px;
  }

  .organization-chart-category {
    align-items: center;
    display: flex;
    color: #123354;
    font-size: 12px;
    font-weight: 500;
    height: 48px;
    justify-content: center;
    line-height: 16px;
    margin-top: 12px;
  }

  .organization-chart-counter {
    background-color: #eaeaf6;
    border-radius: 4px;
    color: #123354;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    position: absolute;
    right: 8px;
    top: 8px;
  }

  .organization-chart-content {
    border-top: 1px solid #bbc0d7;
    margin-top: 12px;
  }

  .organization-chart-sub-category {
    align-items: flex-start;
    border-bottom: 1px solid #e1e2eb;
    display: flex;
    padding: 8px 0;
    text-align: left;
  }

  .organization-chart-sub-category:last-child {
    border: none;
    padding-bottom: 0;
  }

  .organization-chart-sub-category-name {
    color: #123354;
    font-size: 12px;
    line-height: 12px;
    min-width: 0;
  }

  .organization-chart-sub-category-counter {
    background-color: #eaeaf6;
    border-radius: 4px;
    color: #123354;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    margin-left: 8px;
  }

  .organization-chart-line-center-horizontal::before {
    background: #e1e2eb;
    content: "";
    height: 1px;
    left: 72px;
    position: absolute;
    top: 50%;
    width: calc(100% - 142px);
  }

  .organization-chart-line-top-horizontal::before {
    background: #e1e2eb;
    content: "";
    height: 1px;
    left: 72px;
    position: absolute;
    top: -16px;
    width: calc(100% - 143px);
  }

  .organization-chart-line-top-vertical::before {
    background: #e1e2eb;
    content: "";
    height: 16px;
    left: 50%;
    position: absolute;
    top: -16px;
    width: 1px;
  }

  .organization-chart-line-bottom-vertical::after {
    background: #e1e2eb;
    bottom: -16px;
    content: "";
    height: calc(50% + 16px);
    left: 50%;
    position: absolute;
    width: 1px;
  }

  .slide-enter-active, .slide-leave-active {
    max-height: 200px;
    overflow: hidden;
    transition: all .5s ease-in-out;
  }

  .slide-enter, .slide-leave-to {
    max-height: 0;
    opacity: 0;
  }

  ::v-deep .dialog-main {
    align-self: flex-start;
  }
</style>
