import axios from 'axios';
import configuration from '@/configurations';

class QueryRepository {

  searchUserQueryList(token, user_id, criteria = {}) {
    return axios
      .get(
        `${configuration.api_host}/v1/users/${user_id}/queries`,
        {
          params: criteria,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }
}

const query_repository = new QueryRepository();

export {
    query_repository,
};
