<template>
  <div class="card">
    <a
      class="contact"
      :href="`${configuration.desk_v1_host}/contact/${contact.id}/profile?jwt=${token}&direction=${direction}`"
      target="_blank"
    >
      <div class="avatar">
        <v-avatar size="28">
          <contact-photo
            :contact_id="contact.id"
            :civility_id="contact.civility.id"
          />
        </v-avatar>
      </div>

      <div class="informations">
        <div class="name">
          {{ contact.first_name }} {{ contact.last_name }}
        </div>

        <div class="job">
          {{ contact.job_list[0].name }}
        </div>
      </div>
    </a>

    <v-divider
      v-if="main_job.supervisor.person.id"
      class="separator"
    />

    <a
      v-if="main_job.supervisor.person.id"
      class="supervisor"
      :href="`${configuration.desk_v1_host}/contact/${main_job.supervisor.person.id}/profile?jwt=${token}&direction=${direction}`"
      target="_blank"
    >
      <div class="supervisor-tag">
        <div class="tag">
          N+1
        </div>
      </div>

      <div class="supervisor-informations">
        <div class="supervisor-name">
          {{ main_job.supervisor.person.first_name }} {{ main_job.supervisor.person.last_name }}
        </div>

        <div class="supervisor-job">
          {{ main_job.supervisor.name }}

          <v-icon>
            mdi-circle
          </v-icon>

          {{ main_job.supervisor.office.company.name }}
        </div>
      </div>
    </a>

    <div
      v-if="main_job.board_member"
      class="big-tag"
    >
      CoDir
    </div>

    <div
      v-if="!main_job.board_member && main_job.chief_officer"
      class="big-tag"
    >
      C-level
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import configuration from '@/configurations';

import ContactPhoto from '@/components/elements/images/ContactPhoto';

export default {
  name: 'ContactCard',
  components: {
    ContactPhoto,
  },
  props: {
    contact: Object,
  },
  data() {
    return {
      configuration,
    };
  },
  computed: {
    ...mapGetters({
      direction: 'user/direction',
      token: 'user/token',
    }),
    main_job() {
      return this.contact.job_list[0];
    },
  },
};
</script>

<style scoped>
  .card {
    background-color: #ffffff;
    border: 1px solid #e1e2eb;
    border-radius: 4px;
    padding: 8px;
    position: relative;
  }

  .big-tag {
    background-color: #5e81f4;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    padding: 2px 4px;
    position: absolute;
    right: 8px;
    top: 0;
  }

  .contact {
    display: flex;
    text-decoration: none;
  }

  .informations {
    margin-left: 8px;
  }

  .name {
    color: #123354;
    font-size: 12px;
    font-weight: 500;
  }

  .contact:hover .name {
    text-decoration: underline;
  }

  .job {
    color: #123354;
    font-size: 11px;
    margin-top: 4px;
  }

  .separator {
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .supervisor {
    display: flex;
    text-decoration: none;
  }

  .supervisor-tag {
    width: 28px;
  }

  .tag {
    background-color: #bbc0d7;
    border-radius: 4px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    padding: 4px 6px 3px;
  }

  .supervisor-informations {
    margin-left: 8px;
  }

  .supervisor-name {
    color: #9b9bbe;
    font-size: 12px;
    font-weight: 500;
  }

  .supervisor:hover .supervisor-name {
    text-decoration: underline;
  }

  .supervisor-job {
    color: #9b9bbe;
    font-size: 11px;
    margin-top: 4px;
  }

  .supervisor-job .v-icon {
    color: #9b9bbe;
    font-size: 4px;
  }
</style>
