import axios from 'axios';
import configuration from '@/configurations';

class UserRepository {
  getUserById(token, user_id) {
    return axios
      .get(
        `${configuration.api_host}/v1/users/${user_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }
  getUserDataLocalStorage() {
    return localStorage.user_data
      ? JSON.parse(localStorage.user_data)
      : null;
  }
  removeUserDataLocalStorage() {
    localStorage.removeItem('user_data');
  }
  setUserDataLocalStorage(user_data) {
    localStorage.user_data = user_data;
  }
}

const user_repository = new UserRepository();

export {
  user_repository,
};
