<template>
  <img-wrapper
    v-if="display_block"
    :src="contact_photo"
    srcset
    lazy-src
    @error="onImageError"
  >
    <template #placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        />
      </v-row>
    </template>
  </img-wrapper>
</template>

<script>
import ImgWrapper from './ImgWrapper';

const not_found_default_male_logo = require("@/assets/male_avatar.svg");
const not_found_default_female_logo = require("@/assets/female_avatar.svg");

const contact_photo_url_prefix = 'https://storage.googleapis.com/static-portraits-nomination/mini/';
const contact_photo_extention = 'jpg';

export default {
  name: 'ContactPhoto',
  components: {
    ImgWrapper,
  },
  props: {
    civility_id: Number,
    contact_id: Number,
    use_avatar: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      contact_photo: `${contact_photo_url_prefix}${this.contact_id}.${contact_photo_extention}`,
      display_block: true,
      display_avatar: true,
    };
  },
  mounted(){
    if (this.use_avatar !== undefined) {
      this.display_avatar = this.use_avatar;
    }
  },
  methods: {
    onImageError() {
      if (this.display_avatar === false) {
        this.display_block = false;
      }
      this.contact_photo = (this.civility_id == 1 || this.civility_id === undefined)
        ? not_found_default_male_logo
        : not_found_default_female_logo;
    },
  },
};
</script>
