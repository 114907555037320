<template>
  <a
    :class="data.class"
    class="component-container pa-3"
    :href="`${configuration.desk_v1_host}/account/${data.company_id}/summary?jwt=${token}&direction=${direction}`"
    target="_blank"
  >
    <div class="row align-center">
      <div class="flex-column">
        <div class="logo-container pa-2">
          <company-logo :company_id="data.company_id" />
        </div>
      </div>
      <div class="flex-column ml-2">
        <div class="company-name">
          {{ data.company_name }}
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
import configuration from '@/configurations';

import CompanyLogo from '@/components/elements/images/CompanyLogo';

export default {
  name: 'NewsCardCompany',
  components: {
    CompanyLogo,
  },
  props: {
    data: Object,
    queries: Array,
  },
  data() {
    return {
      configuration,
    };
  },
  computed: {
    ...mapGetters({
      direction: 'user/direction',
      token: 'user/token',
    }),
  },
};
</script>

<style scoped>
  .component-container {
    background-color: #f5f5fb;
    cursor: pointer;
    display: block;
    text-decoration: none;
  }

  .logo-container {
    background-color: #ffffff;
    border-radius: 15px;
    margin: 12px;
    width: 70px;
  }

  .company-name {
    color: #131336;
    font-size: 16px;
    font-weight: 500;
  }
</style>
