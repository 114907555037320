import axios from 'axios';
import configuration from '@/configurations';

class NewsRepository {

  getActiveMatches(token, criteria = {}) {
    return axios
      .post(
        `${configuration.api_host}/v1/news/active/matches`,
        criteria,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }


  /**
   * @param {Object} criteria
   * @example "limit":0,"news_publication_date_max":"2020-05-23T14:11:30Z"}
   */
  getActive(token, criteria = {}) {
    return axios
      .post(
        `${configuration.api_host}/v1/news/active`,
        criteria,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }

  /**
   * @param {Object} criteria
   * @example "limit":0,"news_publication_date_max":"2020-05-23T14:11:30Z"}
   */
  getBusinessAlertNews(token, criteria = {}) {
    return axios
      .post(
        `${configuration.api_host}/v1/business-alert/news`,
        criteria,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((result) => result.data);
  }

  /**
   * @param {Object} params
   */
  getBusinessOpportunity(token, params = {}) {
    return axios
      .get(
        `${configuration.api_host}/v1/news/business-opportunities`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      )
      .then((result) => result.data);
  }

  /**
   * @param {Object} params
   */
  getConference(token, params = {}) {
    return axios
      .get(
        `${configuration.api_host}/v1/news/conferences`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      )
      .then((result) => result.data);
  }

  /**
   * @param {Object} params
   */
  getPress(token, params = {}) {
    return axios
      .get(
        `${configuration.api_host}/v1/news/press`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
        }
      )
      .then((result) => result.data);
  }
}

const news_repository = new NewsRepository();

export {
    news_repository,
};
