<template>
  <img-wrapper
    v-if="display_block"
    :src="company_logo"
    srcset
    lazy-src
    contain
    @error="onImageError"
  >
    <template #placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        />
      </v-row>
    </template>
  </img-wrapper>
</template>

<script>
import ImgWrapper from './ImgWrapper';

const not_found_default_logo = require("@/assets/company_avatar.svg");
const company_logo_url_prefix = 'https://storage.googleapis.com/static-logos-nomination/';
const company_logo_extention = 'jpg';

export default {
  name: 'CompanyLogo',
  components: {
    ImgWrapper,
  },
  props: {
    company_id: Number,
    use_avatar: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      company_logo: `${company_logo_url_prefix}${this.company_id}.${company_logo_extention}`,
      display_block: true,
      display_avatar: true,
    };
  },
  mounted() {
    if (this.use_avatar !== undefined) {
      this.display_avatar = this.use_avatar;
    }
  },
  methods: {
    onImageError() {
      if (this.display_avatar === false) {
        this.display_block = false;
      }
      this.company_logo = not_found_default_logo;
    },
  },
};
</script>
