<template>
  <v-card class="mx-auto">
    <news-card-company
      :data="company_data"
      :queries="matched_queries"
    />

    <news-card-type :data="type_data" />

    <news-card-contact :data="contact_data" />
  </v-card>
</template>

<script>
import {NewsConstants} from '@/constants';
import { mapActions } from 'vuex';

import NewsCardCompany from '@/components/elements/news/news_cards/elements/NewsCardCompany';
import NewsCardContact from '@/components/elements/news/news_cards/elements/NewsCardContact';
import NewsCardType from '@/components/elements/news/news_cards/elements/NewsCardType';

export default {
  name: 'NominationNewsCard',
  components: {
    NewsCardCompany,
    NewsCardContact,
    NewsCardType,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      contact_data: {
        civility_id: this.data.person.civility.id,
        class: '',
        id: this.data.person.id,
        first_name: this.data.person.first_name,
        job_name: this.data.person.main_job.name,
        last_name: this.data.person.last_name,
      },
      company_data: {
        class: 'mt-3',
        company_id: this.data.person.main_job.office.company.id,
        company_name: this.data.person.main_job.office.company.name,
      },
      content_data: {
        class: '',
      },
      type_data: {
        class: '',
        publication_date: this.data.publication_date,
        types: [
          {
            icon: NewsConstants.TYPE.NOMINATION.ICON,
            id: NewsConstants.TYPE.NOMINATION.ID,
            color: NewsConstants.TYPE.NOMINATION.COLOR,
            label: NewsConstants.TYPE.NOMINATION.LABEL,
          },
        ],
      },
      company_id: this.data.person.main_job.office.company.id,
      contact_id: this.data.person.id,
      description: `${this.data.person.first_name} ${this.data.person.last_name} est nommé(e) ${this.data.person.main_job.name}`,
      id: this.data.id,
      publication_date: this.data.publication_date,
      matched_queries: this.data.matched_queries,
      TYPE: NewsConstants.TYPE.NOMINATION,
      contact: {},
    };
  },
  methods: {
    ...mapActions({
      getContact: 'contact/getContact',
    }),
    mainJob(contact) {
      if (contact.job_list === undefined){
        return {};
      }

      let main_job = contact.job_list.find((job) => job.main == true && job.active == true);

      if (main_job === undefined) {
        main_job = contact.job_list.find((job) => job.main == true);
      }

      return main_job || {};
    },
  },
};
</script>

<style scoped>
  .description {
    color: #123354;
    font-size: 12px;
  }

  .published-date {
    color: #9b9bbe;
    font-size: 12px;
  }
</style>
