<template>
  <v-container
    class="news-page"
    fluid
  >
    <v-row
      v-for="news_card in news_list"
      :key="news_card.id"
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="6"
      >
        <news-card :data="news_card" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { NewsConstants } from '@/constants';
import store from '@/store';

import NewsCard from '@/components/elements/news/NewsCard';

export default {
  name: "NewsPage",
  components: {
    NewsCard,
  },
  data() {
    return {
      use_type_criterion: false,
      use_tag_criterion: true,
      use_news_use_personnal_queries_criterion: true,
      use_publication_date: false,
      news_publication_period: 2,
      news_type_list: NewsConstants.DEFAULT_MANAGED_NEWS_TYPE_LIST,
      news_tag_list: NewsConstants.DEFAULT_MANAGED_NEWS_TAG_LIST,
      use_personnal_queries: true,
      query_id_list: [],
      criteria: {},
      bottom: false,
    };
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
      news_list: 'news_page/newsList',
      limit: 'news_page/limit',
      offset: 'news_page/offset',
      news_is_loading: 'news_page/offset',
    }),
  },
  watch: {
    bottom: function(value) {
      if (value && !this.news_is_loading) {
        if (this.criteria.query_id_list.length === 0) {
          return;
        }

        this.isLoading(true);
        this.searchNewsNextPage()
          .finally(() => this.isLoading(false));
      }
    },
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible();
    });

    if (this.news_list.length == 0) {
      this.isLoading(true);
      this.getNewsQueryList()
        .then((query_list) => {
          this.query_id_list = query_list.map((query) => query.id);
          
          return this.search();
        });
    }
  },
  methods: {
    ...mapActions({
      isLoading: 'app_layout/setLoading',
      searchNews: 'news_page/searchNews',
      resetNewsList: 'news_page/resetNewsList',
      searchNewsNextPage: 'news_page/searchNewsNextPage',
      getNewsQueryList: 'user/getNewsQueryList',
      saveUserDefinedCriteria: 'news_page/saveUserDefinedCriteria',
      updateUserDefinedCriteria: 'news_page/updateUserDefinedCriteria',
    }),
    search() {
      this.isLoading(true);

      this.criteria.query_id_list = this.query_id_list;

      if (this.criteria.query_id_list.length === 0) {
        this.isLoading(false);

        return;
      }

      this.searchNews(this.criteria)
        .finally(() => this.isLoading(false));
    },
    bottomVisible() {
      const bottom_visible_offest = 100;

      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= (pageHeight - bottom_visible_offest);

      return bottomOfPage || pageHeight < (visible + bottom_visible_offest);
    },
  },
};
</script>

<style scoped>
  .news-page {
    padding: 0;
  }

  .filters {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .drawer-header {
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5;
  }

  .drawer-close {
    height: 40px;
    position: absolute;
  }

  .drawer-title {
    font-size: 16px;
    font-weight: 500;
    height: 40px;
    justify-content: center;
  }

  .drawer-content {
    height: calc(100vh - 60px - 56px - 60px);
    margin-top: 56px;
    overflow: hidden;
    overflow-y: visible;
  }

  .drawer-footer {
    left: 0;
    position: fixed;
    width: 100%;
    z-index: 5;
  }

  .see-alerts {
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
  }

  .filter-item {
    font-size: 12px;
    font-weight: 500;
    text-transform: none;
  }
</style>
