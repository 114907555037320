import {
  OrganizationChart,
  OrganizationChartConstants,
} from '@/constants';
import {
  contact_repository,
} from '@/repositories/ContactRepository';

const formatOrganizationChart = (contact_list) => {
  const organization_chart = [...OrganizationChart];

  organization_chart.forEach((row) => {
    row.show = false;

    row.job_class_list.forEach((job_class) => {
      job_class.open = false;
      job_class.show = false;

      job_class.job_type_list.forEach((job_type) => {
        job_type.counter = 0;
        job_type.contact_list = [];
        job_type.show = false;
      });
    });
  });

  const organization_chart_level_three = organization_chart
    .find((row) => row.level === OrganizationChartConstants.LAST_LEVEL);
  const unassigned_contacts_job_class = organization_chart_level_three
    .job_class_list
    .find((job_class) => job_class.id === OrganizationChartConstants.OTHER_ID);
  const unassigned_contacts_job_type = unassigned_contacts_job_class.job_type_list[0];

  contact_list.forEach((contact) => {
    contact.job_list.forEach((job) => {
      if (job.job_type_list.length === 0) {
        addContactToJobType(contact, unassigned_contacts_job_type);
      }

      job.job_type_list.forEach((job_type) => {
        const organization_chart_job_type = findJobType(organization_chart, job_type.id);

        if (organization_chart_job_type !== null) {
          addContactToJobType(contact, organization_chart_job_type);
        }
      });
    });
  });

  organization_chart.forEach((row) => {
    row.job_class_list.forEach((job_class) => {
        if (job_class.job_type_list.some((job_type) => job_type.show)) {
          row.show = true;
          job_class.show = true;

          job_class.counter = job_class.job_type_list
            .reduce((accumulator, value) => accumulator + value.counter, 0);
        }
    });
  });

  return organization_chart;
};

const findJobType = (organization_chart, job_type_id) => {
  for(let i=0; i<organization_chart.length; i++) {
    const row = organization_chart[i];

    for(let j=0; j<row.job_class_list.length; j++) {
      const job_class = row.job_class_list[j];

      for(let k=0; k<job_class.job_type_list.length; k++) {
        const job_type = job_class.job_type_list[k];

        if (job_type.id === job_type_id) {
          return job_type;
        }
      }
    }
  }

  return null;
};

const addContactToJobType = (contact, job_type) => {
  if (job_type.contact_list.some((contact_item) => contact_item.id === contact.id)) {
    return;
  }

  job_type.counter++;
  job_type.contact_list.push(contact);
  job_type.show = true;
};

const state = () => ({
  contact_data: {},
  organization_chart_data: [],
});

const getters = {
  contactData: (state) => state.contact_data,
  organizationChartData: (state) => state.organization_chart_data,
};

const actions = {
  getContact({ commit, rootState}, contact_id) {
    const token = rootState.user.token;
    return contact_repository
      .getContact(token, contact_id)
      .then((response) => {
        commit('set_contact_data', response);
        return response;
      });
  },
  getOrganizationChart({ commit, rootState}, criteria) {
    const token = rootState.user.token;
    return contact_repository
      .getOrganizationChart(token, criteria)
      .then((response) => {
        const formated_organization_chart = formatOrganizationChart(response.contact_list);

        commit('set_organization_chart_data', formated_organization_chart);

        return formated_organization_chart;
      });
  },
};

const mutations = {
  set_contact_data(state, data) {
    state.contact_data = data;
  },
  set_organization_chart_data(state, data) {
    state.organization_chart_data = data;
  },
};

export default {
  actions,
  getters,
  mutations,
  namespaced: true,
  state,
};
