<template>
  <v-card class="mx-auto">
    <news-card-company
      :data="company_data"
      :queries="matched_queries"
    />

    <news-card-type :data="type_data" />

    <news-card-contact :data="contact_data" />

    <news-card-content :data="content_data">
      <div class="conference-name">
        {{ data.highlight.conference.name }}
      </div>

      <div
        v-if="data.highlight.conference.address.city.name !== ''"
        class="d-flex align-center mt-2"
      >
        <v-icon
          class="mr-1"
          color="#9b9bbe"
          x-small
        >
          mdi-map-marker
        </v-icon>

        <span class="city">
          {{ data.highlight.conference.address.city.name }}
        </span>
      </div>

      <div class="description mt-2">
        {{ description }}
      </div>
    </news-card-content>
  </v-card>
</template>

<script>
import configuration from '@/configurations';
import {NewsConstants} from '@/constants';
import { mapActions } from 'vuex';
import moment from 'moment';

import NewsCardCompany from '@/components/elements/news/news_cards/elements/NewsCardCompany';
import NewsCardContact from '@/components/elements/news/news_cards/elements/NewsCardContact';
import NewsCardContent from '@/components/elements/news/news_cards/elements/NewsCardContent';
import NewsCardType from '@/components/elements/news/news_cards/elements/NewsCardType';

export default {
  name: 'SpeakerNewsCard',
  components: {
    NewsCardCompany,
    NewsCardContact,
    NewsCardContent,
    NewsCardType,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      contact_data: {
        civility_id: this.data.person.civility.id,
        class: '',
        id: this.data.person.id,
        first_name: this.data.person.first_name,
        job_name: this.data.person.main_job.name,
        last_name: this.data.person.last_name,
      },
      company_data: {
        class: 'mt-3',
        company_id: this.data.person.main_job.office.company.id,
        company_name: this.data.person.main_job.office.company.name,
      },
      content_data: {
        class: '',
        link: `${configuration.desk_v1_host}/conference/${this.data.highlight.conference.id}/program`,
      },
      type_data: {
        class: '',
        publication_date: this.data.publication_date,
        types: [
          {
            icon: NewsConstants.TYPE.SPEAKER.ICON,
            id: NewsConstants.TYPE.SPEAKER.ID,
            color: NewsConstants.TYPE.SPEAKER.COLOR,
            label: NewsConstants.TYPE.SPEAKER.LABEL,
          },
        ],
      },
      contact_id: this.data.person.id,
      company_id: this.data.person.main_job.office.company.id,
      id: this.data.id,
      publication_date: this.data.publication_date,
      title: `${this.data.person.first_name} ${this.data.person.last_name}`,
      matched_queries: this.data.matched_queries,
    };
  },
  computed: {
    description: function() {
      const civility_id = this.data.person.civility.id;
      const type = this.data.highlight.highlight_type.name;
      const name = this.data.highlight.name;
      const date = this.data.highlight.start_date;
      const pronoun = `${civility_id == 1 ? 'Il' : 'Elle'}`;
      const description = 'interviendra dans le cadre';
      const preposition = this.getSpeakerTypePreposition(type);
      return [
        pronoun,
        description,
        `${preposition}${type}`,
        `"${name}"`,
        'le',
        `${moment(date).format('DD/MM/YYYY')}.`,
      ].join(' ');
    },
  },
  methods: {
    ...mapActions({
      'getContact': 'contact/getContact',
    }),
    mainJob(contact) {
      if (contact.job_list === undefined){
        return {};
      }

      let main_job = contact.job_list.find((job) => job.main == true && job.active == true);

      if (main_job === undefined) {
        main_job = contact.job_list.find((job) => job.main == true);
      }

      return main_job || {};
    },
    getSpeakerTypePreposition(type) {
      let preposition = null;
      if (
        [
          'Conférence de clôture',
          'Conférence d\'ouverture',
          'Conférence', 'Convention',
          'Démonstration',
          'Foire',
          'Formation',
          'Journée',
          'Présentation',
          'Remise de prix',
          'Rencontre',
          'Réunion',
          'Soirée',
          'Table ronde',
        ].includes(type)
      ) {
        preposition = 'de la ';
      } else if (
        [
          'Colloque',
          'Congrès',
          'Débat',
          'Forum',
          'Meeting',
          'Petit déjeuner débat',
          'Rendez-vous',
          'Salon',
          'Séminaire',
          'Petit-déjeuner débat',
        ].includes(type)
      ) {
        preposition = 'du ';
      } else if (
        [
          'Animation',
          'Assemblée',
          'Atelier',
          'Expo',
          'Exposition',
          'Inauguration',
        ].includes(type)
      ) {
        preposition = 'de l\'';
      } else if (
        [
          'Assises',
          'Journées',
        ].includes(type)
      ) {
        preposition = 'des ';
      } else {
        preposition = '';
      }

      return preposition;
    },
  },
};
</script>

<style scoped>
  .conference-name {
    color: #131336;
    font-size: 16px;
    font-weight: 500;
  }

  .city {
    color: #9b9bbe;
    font-size: 12px;
    text-transform: uppercase;
  }

  .description {
    color: #123354;
    font-size: 12px;
  }

  .published-date {
    color: #9b9bbe;
    font-size: 12px;
  }
</style>
