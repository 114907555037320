'use strict';

const QUERY_TYPE_ID = {
    ALERT: 1,
};

const STATUS = {
    ACTIVE: {
        ID: 1,
        LABEL: 'actif',
    },
    INACTIVE: {
        ID: 2,
        LABEL: 'inactif',
    },
    SUSPENDED: {
        ID: 3,
        LABEL: 'suspendu',
    },
};

const GlobalConstants = {
    QUERY_TYPE_ID,
    STATUS,
};

export {
    GlobalConstants,
};
